.wwd-wrapper {
    position: relative
}

/* .wwd-side-elements {
    position: absolute;
    z-index: 0;
    top: 50%
} */

.wwd-container {
    margin-top: 10rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.wwd-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    width: 75%
}

.wwd-blocks {
    width: 100%;
    display: flex;
    gap: 2rem
}

.wwd-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    box-shadow: var(--shadow);
    border-radius: 2rem;
    z-index: 99;
    background-color: #fff
}

.block-features {
    margin-top: 1rem;
    display: flex;
    gap: 1rem
}

.block-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    flex: 1;
    border: 1px solid hsla(0, 0%, 59%, .17);
    border-radius: 1rem;
    padding: 1rem;
    color: var(--title-color);
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-weight: 500;
    height: fit-content;
}


.wwd-block:nth-child(1)>.block-features> :nth-child(2) {
    margin-top: 3rem
}

.wwd-block:nth-of-type(2)>.block-features> :nth-child(1),
.wwd-block:nth-of-type(2)>.block-features> :nth-child(3) {
    margin-top: 2rem;
}


.wwd-support {
    display: flex;
    padding: 2rem;
    box-shadow: var(--shadow);
    border-radius: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
    background: #fff
}

.wwd-support>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem
}

.wwd-support> :nth-child(1) {
    flex: 1;
}

.wwd-support> :nth-child(2) {
    flex: 1.6;
}

@media (max-width: 990px) {
    .wwd-blocks {
        flex-direction: column
    }
}

@media (max-width: 768px) {
    .wwd-support {
        flex-direction: column
    }
}

@media (max-width: 576px) {
    .wwd-block {
        width: 80%;
        margin: auto
    }

    .block-features {
        flex-direction: column
    }

    .block-feature {
        margin-top: 0 !important
    }
}