.emailBox {
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 999px;
    display: flex;
    align-items: center;
    width: 70%;
    position: relative;
    overflow: hidden
}

.emailBox>input {
    outline: none;
    border: none;
    margin-left: 1rem;
    font-size: 1rem;
    color: #969696;
    font-family: '__Josefin_Sans_584c1b, __Josefin_Sans_Fallback_584c1b';
    flex: 1;
}

.emailBox>input::placeholder {
    font-family: Inter;
    font-size: .8rem
}

.getFunded {
    position: absolute;
    background-color: var(--secondary-color);
    height: 80%;
    right: .5rem;
    padding: 0 2rem;
    border-radius: 999px;
    color: #fff;
    display: flex;
    align-items: center;
}