.f-wrapper .container {
    margin-top: 5rem;
    padding-top: 5rem;
    padding-bottom: 2rem
}

.f-wrapper {
    background-color: cyan
}

.f-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center
}

.f-container .title {
    color: #fff
}

.f-container .emailBox {
    max-width: 36rem
}

.f-container hr {
    border-color: var(--border-color-light);
    width: 100%
}

.f-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap
}

.f-menu span {
    color: #fff;
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: bold
}

@media (max-width: 990px) {
    .f-menu {
        flex-direction: column;
        gap: 2rem;
        align-items: center
    }
}