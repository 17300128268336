.n-wrapper {
    background: var(--primary-color);
    width: 100%;
    position: absolute;
    z-index: 999
}

.sticky {
    position: fixed;
    box-shadow: 10px 12px 48px rgba(181, 187, 203, .206);
    background: #fff;
    top: 0
}

.n-container {
    padding: 1.5rem 1rem;
    color: #fff;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500
}

.sticky .n-container {
    padding: 1rem;
    color: var(--title-color)
}

.n-logo {
    font-weight: 1000;
    cursor: pointer
}

.n-right {
    text-transform: none;
    font-size: 1.5rem
}

.n-menu,
.n-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem
}

.n-menu span {
    cursor: pointer
}

.fund-button {
    color: var(--dark-bg);
    background-color: var(--third-color);
    padding: 1rem 1.6rem;
    border-radius: 999px;
    font-size: 1rem;
    text-transform: capitalize
}

.nm-container {
    display: none
}

@media (max-width: 1280px) {
    .n-menu {
        gap: 2rem
    }
}

@media (max-width: 1200px) {
    .n-wrapper {
        position: fixed
    }

    .n-container {
        display: none
    }

    .nm-container {
        display: block;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        position: relative;
        background-color: var(--primary-color)
    }

    .nm-container>:first-child {
        font-weight: 700;
    }

    .nm-menu {
        position: fixed;
        top: 3.9rem;
        padding: 3rem 2rem;
        box-sizing: border-box;
        left: 0;
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        width: 100%;
        transform: translateX(-150%);
        transition: all .3s ease-in-out;
        height: calc(100vh - 3.9rem);
        background: var(--primary-color);
        z-index: 99
    }

    .nm-menu a {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--border-color)
    }

    .m-fund-button {
        width: 100%;
        background-color: var(--third-color);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 1rem 0;
        border-radius: 999px;
        color: var(--dark-bg)
    }
}