.h-wrapper {
    background-color: navy;
    height: 120vh;
    overflow: hidden
}

.h-wrapper .container {
    padding-top: 8rem
}

.h-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 80vh;
    position: relative
}

.h-arrow {
    position: absolute;
    top: 5rem;
    width: 70%;
    float: left;
    max-width: 60rem;
    z-index: 0;
    opacity: .3;
}

/* left side */
.h-left {
    flex: 1;
    position: relative;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem
}

.image-row {
    display: flex;
    gap: 2rem
}

.person-pill {
    height: 15rem;
    width: 6rem;
    border-radius: 999px;
    overflow: hidden
}

.person-pill-bg {
    height: 100%;
    width: 100%;
    border-radius: 999px;
    overflow: hidden;
}


.person-pill-bg>img {
    width: 100%;
    object-fit: cover;
    scale: 2;
    margin-top: 4rem
}

.image-row>:nth-child(2) {
    transform: translateY(-5rem)
}




/* Right side */
.h-right {
    flex: 1.25;
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.h-title {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: var(--third-color);
    font-size: 4rem;
    text-transform: none;
    font-weight: 700;
}

.h-title> :nth-child(3) {
    color: #fff;
}

.h-des {
    color: #fff;
    font-size: 1.4rem;
    line-height: 2rem
}



@media (max-width: 1024px) {
    .h-wrapper {
        height: max-content
    }

    .h-wrapper .container {
        padding-top: 5rem
    }

    .h-container {
        height: auto;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        padding-bottom: 3rem
    }

    .h-arrow {
        float: left;

    }

    .h-right {
        flex: 1;
        align-items: center;
    }

    .h-title {
        align-items: center;
        font-size: 2.2rem;
    }


    .h-des {
        font-size: 1.2rem;
        width: 80%;
        text-align: center
    }

    .h-getFunded {
        padding: 0 1rem
    }
}