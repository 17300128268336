.t-wrapper .container {
    padding-top: 13rem
}

.t-container {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center
}

.t-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 50rem
}


.slick-slider {
    margin-top: 5rem
}

.slick-track {
    padding: 4rem 0
}

.slick-slide>div {
    padding: 0 20px
}

.slick-dots {
    bottom: 0 !important
}

.comment {
    display: flex !important;
    flex-direction: column;
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    padding: 2rem;
    gap: 3rem
}

.c-content {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem
}

.c-content span {
    font-size: 1rem;
    line-height: 1.5rem
}

.c-info {
    gap: 1rem;
    display: flex;
    align-items: center
}

.c-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #877eb5;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 100%;
    width: 3rem;
    height: 3rem
}

.c-person {
    display: flex;
    flex-direction: column;
    gap: .5rem
}

.c-person> :nth-child(1) {
    font-weight: bold;
}

.c-person> :nth-child(2) {
    font-weight: 700;
    color: hsla(0, 0%, 50%, .688);
    font-size: .8rem
}

@media (min-width: 1280px) {
    .slick-slide.slick-active.slick-current+.slick-slide .comment {
        transition: all 1s ease-out;
        scale: 1.1;
        transform: translateY(-30px);
        box-shadow: var(--shadow)
    }
}